<template lang='pug'>
	.wizard-wrap-xl.container-xs-24.mx-sm-auto.mt-4.mb-5.lh-18
		h1.mb-6 {{ $t('agency.title') }}
		p.text-muted.mb-4 {{ $t('date.published') }}: {{ $t(`agency.published`) }}

		p.mb-4 {{ $t('agency.head[0]') }}
		p.mb-4 {{ $t('agency.head[1]') }}

		hr.primary.my-6

		h2.mb-6 1. {{ $t('agency.common[0].title') }}
		p {{ $t('agency.common[0].desc') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '1'")
			li(v-for='i in ul0' :key='i.title')
				span
					b {{ i.title }}&nbsp;
					span(v-if='!i.noDef') -&nbsp;
					span(v-html='i.text')

		hr.primary.my-6

		h2.mb-6 2. {{ $t('agency.common[1].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '2'")
			li(v-for='i in common[1].ul' :key='i.text')
				div(v-if='i.ulDeep')
					span(v-html='i.text')
					ol.ol-deep.mt-2(style="--n: '2'" v-if='i.ulDeep')
						li(v-for='d in i.ulDeep' :key='d.text')
							div(v-html='d.text')
				span(v-else v-html='i.text')

		hr.primary.my-6

		h2.mb-6 3. {{ $t('agency.common[2].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '3'")
			li(v-for='i in common[2].ul' :key='i.text')
				div(v-if='i.ulDeep')
					span(v-html='i.text')
					ol.ol-deep.mt-2(style="--n: '3'" v-if='i.ulDeep')
						li(v-for='d in i.ulDeep' :key='d.text')
							div(v-html='d.text')
				span(v-else v-html='i.text')

		hr.primary.my-6

		h2.mb-6 4. {{ $t('agency.common[3].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '4'")
			li(v-for='i in common[3].ul' :key='i.text')
				div(v-if='i.ulDeep')
					span(v-html='i.text')
					ol.ol-deep.mt-2(style="--n: '4'" v-if='i.ulDeep')
						li(v-for='d in i.ulDeep' :key='d.text')
							div(v-html='d.text')
				span(v-else v-html='i.text')

		hr.primary.my-6

		h2.mb-6 5. {{ $t('agency.common[4].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '5'")
			li(v-for='i in common[4].ul' :key='i.text')
				span(v-html='i.text')

		hr.primary.my-6

		h2.mb-6 6. {{ $t('agency.common[5].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '6'")
			li(v-for='i in common[5].ul' :key='i.text')
				span(v-html='i.text')

		hr.primary.my-6

		h2.mb-6 7. {{ $t('agency.common[6].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '7'")
			li(v-for='i in common[6].ul' :key='i.text')
				span(v-html='i.text')

		hr.primary.my-6

		h2.mb-6 8. {{ $t('agency.common[7].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '8'")
			li(v-for='i in common[7].ul' :key='i.text')
				span(v-html='i.text')

		hr.primary.my-6

		h2.mb-6 9. {{ $t('agency.common[8].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '9'")
			li(v-for='i in ul8' :key='i.text')
				span(v-html='i.text')

		hr.primary.my-6

		h2.mb-6 10. {{ $t('agency.common[9].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '10'")
			li(v-for='i in common[9].ul' :key='i.text')
				span(v-html='i.text')

		hr.primary.my-6

		h2.mb-6 11. {{ $t('agency.common[10].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '11'")
			li(v-for='i in common[10].ul' :key='i.text')
				div(v-if='i.ulDeep')
					span(v-html='i.text')
					ol.ol-deep.mt-2(style="--n: '11'" v-if='i.ulDeep')
						li(v-for='d in i.ulDeep' :key='d.text')
							div(v-html='d.text')
				span(v-else v-html='i.text')
	
		h2.mb-6 12. {{ $t('forms.requisites') }}
		company-data.mb-4
		company-bank-data.mb-5
		
		.mb-5 &nbsp;
		
		
		
		.overflow-hidden
			.float-right {{ $t('agency.addon.desc[0]') }}
		.overflow-hidden.mb-5
			.float-right {{ $t('agency.addon.desc[1]') }}
		h2.d-block.text-center {{ $t('agency.addon.title') }}
		.mb-5.text-center {{ $t('agency.addon.desc[2]') }}

		.overflow-hidden.mb-4
			.float-left {{ $t('agency.addon.city') }}
			.float-right «___» _____________ 2021 г.

		p(v-html="$t('agency.addon.body')")

		ol.mb-4(style="--n: '1'")
			li {{ $t('agency.addon.list[0]') }}
				.vuetable
					.table-responsive
						table.mt-2.table.b-table.table-bordered
							tbody
								tr(v-for='(i, ind) in tableTr')
									td.f-1 {{ ind + 1 }}
									td {{ i[0] }}
									td {{ i[1] }}

			li {{ $t('agency.addon.list[1]') }}
		br
		br
		pw-btn-up
</template>

<script>
import CompanyData from '@/components/Common/Company'
import CompanyBankData from '@/components/Common/CompanyBank'
import PwBtnUp from '@/components/Buttons/PwBtnUp'
import moment from 'moment'
import {bus} from '@/main'

export default {
	metaInfo () { return {title: bus.$t('agency.title')}},
	components: {
		CompanyData,
		CompanyBankData,
		PwBtnUp
	},
	data : () => ({
		
	}),
	computed: {
		common () { return bus.$t('agency.common') },
		tableTr () {
			return _.cloneDeep(bus.$t('agency.addon.tableTr'));
		},
		ul0 () {
			let ul = _.cloneDeep(bus.$t('agency.common[0].ul'));

			_.each(ul, (m, i) => {
				ul[i].text = ul[i].text.replace(/\$\{bus\.botPayName\}/, bus.botPayName);
				ul[i].text = ul[i].text.replace(/\$\{bus\.botPay\}/, bus.botPay);
				ul[i].text = ul[i].text.replace(/\$\{bus\.botSupportName\}/, bus.botSupportName);
				ul[i].text = ul[i].text.replace(/\$\{bus\.botSupport\}/, bus.botSupport);
				ul[i].text = ul[i].text.replace(/\$\{bus\.botLink\}/, bus.botLink);
			})

			return ul;
		},
		ul8 () {
			let ul = _.cloneDeep(bus.$t('agency.common[8].ul'));

			_.each(ul, (m, i) => {
				ul[i].text = ul[i].text.replace(/\$\{bus\.botPayName\}/, bus.botPayName);
				ul[i].text = ul[i].text.replace(/\$\{bus\.botPay\}/, bus.botPay);
				ul[i].text = ul[i].text.replace(/\$\{bus\.botSupportName\}/, bus.botSupportName);
				ul[i].text = ul[i].text.replace(/\$\{bus\.botSupport\}/, bus.botSupport);
				ul[i].text = ul[i].text.replace(/\$\{bus\.botLink\}/, bus.botLink);
			})

			return ul;
		}
	},
	created () {
		window.scrollTo(0,0);
	}
}
</script>
